/**
 * Canvas animation
 * ======================================
 * - generate random elements in div
 */

const ELEMENT = document.querySelector('#canvas')
const COUNT = window.outerWidth < 992 ? 30 : 60

export class Canvas {
	constructor() {
		for (let i = 0; i < COUNT; i++) {
			ELEMENT.insertAdjacentHTML('beforeend', this.generateSvgs())
		}

		if (!window.matchMedia('(pointer: coarse)').matches) {
			ELEMENT.addEventListener('mousemove', this.mousemoveHandler, false)
			ELEMENT.addEventListener('mouseleave', this.mouseleaveHandler, false)
		}
	}

	generateSvgs = () => {
		const arr = ['circle', 'square', 'triangle', 'polygon', 'square', 'triangle', 'polygon']
		const type = Math.floor(Math.random() * arr.length)
		const rotate = this.getRandomInt(0, 360)

		const html =
			"<div><svg style='transform: rotate(" +
			rotate +
			"deg)' class='icon icon-" +
			arr[type] +
			"'><use xlink:href='#icon-" +
			arr[type] +
			"'></use></svg></div>"

		return html
	}

	getRandomInt = (min, max) => {
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min + 1)) + min
	}

	mousemoveHandler = e => {
		const icons = ELEMENT.querySelectorAll('div')
		const x = e.clientX
		const y = e.clientY
		const radius = 350

		icons.forEach(icon => {
			const pos = icon.getBoundingClientRect()

			const iconX = pos.x - radius
			const iconY = pos.y - radius
			const iconW = pos.width + radius
			const iconH = pos.height + radius

			if (x > iconX && x < pos.x + iconW && y > iconY && y < pos.y + iconH) {
				icon.classList.add('enter')
			} else {
				icon.classList.remove('enter')
			}
		})
	}

	mouseleaveHandler = () => {
		const icons = ELEMENT.querySelectorAll('div')
		icons.forEach(icon => {
			icon.classList.remove('enter')
		})
	}
}
