/**
 * Animate
 * ======================================
 * - add class to element in viewport
 */

const RATIO = '0.90'

export class Animate {
	constructor() {
		this.sections = document.querySelectorAll('.animate')

		window.addEventListener('scroll', this.scrollHandler, false)

		this.scrollHandler()
	}

	scrollHandler = () => {
		if (!document.querySelectorAll('.animate:not(.visible)')) return

		for (const section of this.sections) {
			if (
				section.getBoundingClientRect().top <= window.innerHeight * RATIO &&
				section.getBoundingClientRect().top > 0
			) {
				setTimeout(() => {
					section.classList.add('visible')
				}, 100)
			}
		}
	}
}
